import { useCallback, useMemo } from 'react';
import { PAGE_SIZE } from '../libcarsync/base';
import { buildQuery } from '../libcarsync/query';
// Functionality
export function getInitialPageState(query) {
    return { ids: [], query, lastQueried: 0, loadedPages: -1, totalPages: 0, totalItems: 0 };
}
export function getInitialState() {
    return { items: {}, byQuery: {}, selected: { query: {}, page: 0 } };
}
export function paginationReducer(state, action) {
    const formattedQuery = buildQuery(action.payload.query);
    if (!state.byQuery[formattedQuery]) {
        state.byQuery[formattedQuery] = {
            ids: [],
            query: action.payload.query,
            lastQueried: 0,
            loadedPages: -1,
            totalPages: 0,
            totalItems: 0,
        };
    }
    const pageState = state.byQuery[formattedQuery];
    const pageIncreased = action.payload.page > pageState.loadedPages;
    const itemsInPageIncreased = action.payload.page == pageState.loadedPages && action.payload.totalItems > pageState.totalItems;
    // additems to state
    action.payload.list.forEach((item) => (state.items[item.id] = item));
    pageState.lastQueried = Date.now();
    // update pagination item list
    if (pageIncreased) {
        const newIds = action.payload.list.map((i) => i.id);
        pageState.ids = [...pageState.ids, ...newIds];
        pageState.loadedPages = action.payload.page;
    }
    else if (itemsInPageIncreased) {
        const newIds = action.payload.list.map((i) => i.id).slice(pageState.totalItems, action.payload.totalItems);
        pageState.ids = [...pageState.ids, ...newIds];
    }
    pageState.totalItems = action.payload.totalItems != -1 ? action.payload.totalItems : pageState.totalItems;
    pageState.totalPages = action.payload.totalPages != -1 ? action.payload.totalPages : pageState.totalPages;
}
export function selectPageReducer(state, action) {
    state.selected = {
        page: action.payload.page,
        query: action.payload.query,
    };
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = function () { };
export const EMPTY_PAGE = {
    items: [],
    reload: noop,
    next: noop,
    prev: noop,
    page: 0,
    query: {},
    lastQueried: 0,
    totalItems: 0,
    totalPages: 0,
};
export function getPage(state, cb, selected) {
    const _selected = selected !== null && selected !== void 0 ? selected : state.selected;
    const pageState = state.byQuery[buildQuery(_selected.query)];
    const next = useCallback(() => {
        if (_selected.page + 1 < pageState.totalPages) {
            cb(_selected.page + 1, pageState.query);
        }
    }, [_selected.page, _selected.query, pageState, cb]);
    const prev = useCallback(() => {
        if (_selected.page > 0) {
            cb(_selected.page - 1, pageState.query);
        }
    }, [_selected.page, _selected.query, pageState, cb]);
    const reload = useCallback(() => cb(_selected.page, Object.assign({}, pageState.query)), [_selected.page, pageState, cb]);
    const items = pageState == null
        ? []
        : pageState.ids
            .slice(_selected.page * PAGE_SIZE, (_selected.page + 1) * PAGE_SIZE)
            .map((id) => state.items[id]);
    return useMemo(() => {
        var _a, _b, _c, _d;
        return ({
            page: _selected.page,
            items,
            totalPages: (_a = pageState === null || pageState === void 0 ? void 0 : pageState.totalPages) !== null && _a !== void 0 ? _a : 0,
            totalItems: (_b = pageState === null || pageState === void 0 ? void 0 : pageState.totalItems) !== null && _b !== void 0 ? _b : 0,
            query: (_c = pageState === null || pageState === void 0 ? void 0 : pageState.query) !== null && _c !== void 0 ? _c : {},
            lastQueried: (_d = pageState === null || pageState === void 0 ? void 0 : pageState.lastQueried) !== null && _d !== void 0 ? _d : 0,
            next,
            prev,
            reload,
        });
    }, [pageState, next, prev, reload]);
}
