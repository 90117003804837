import { BASE_URL, pagedRequest, request } from './base';
import { buildQuery } from './query';
const VEHICLE_URL = `https://vehicle.${BASE_URL}`;
export var GearType;
(function (GearType) {
    GearType["MANUAL"] = "MANUAL";
    GearType["AUTOMATIC"] = "AUTOMATIC";
})(GearType || (GearType = {}));
export var TyreType;
(function (TyreType) {
    TyreType["SUMMER"] = "SUMMER";
    TyreType["WINTER"] = "WINTER";
    TyreType["ALL_SEASON"] = "ALL_SEASON";
})(TyreType || (TyreType = {}));
export var FuelType;
(function (FuelType) {
    FuelType["ELECTRIC"] = "ELECTRIC";
    FuelType["GASOLINE"] = "GASOLINE";
    FuelType["DIESEL"] = "DIESEL";
    FuelType["LPG"] = "LPG";
    FuelType["CNG"] = "CNG";
    FuelType["HYBRID"] = "HYBRID";
})(FuelType || (FuelType = {}));
export var VehicleType;
(function (VehicleType) {
    VehicleType["CAR"] = "CAR";
    VehicleType["BIKE"] = "BIKE";
    VehicleType["MOTORBIKE"] = "MOTORBIKE";
})(VehicleType || (VehicleType = {}));
export function getVehicles(page, query) {
    return pagedRequest(`${VEHICLE_URL}/vehicle?${query ? 'query=' + buildQuery(query) : ''}`, {
        page,
    });
}
export function getAllVehicles(query) {
    const q = typeof query === 'string' ? query : buildQuery(query !== null && query !== void 0 ? query : {});
    return request(`${VEHICLE_URL}/vehicle?${query ? 'query=' + q : ''}`);
}
export function getVehicle(id) {
    return request(`${VEHICLE_URL}/vehicle/${id}`).then((res) => res.payload);
}
export function formatVehicleString(vehicle) {
    return [vehicle.manufacturer, vehicle.brand, vehicle.model, '(' + vehicle.licensePlate + ')']
        .filter(Boolean)
        .join(' ');
}
