import { ServiceName } from './domain/Services';
// feature names will be translated
const features = {
    HOME: {
        path: '/',
        service: ServiceName.AUTH,
        name: 'home',
        inMenu: false,
        requiredPermissions: [],
    },
    TRIPDETAIL: {
        path: '/trip/:id',
        service: ServiceName.TRIP,
        name: 'tripdetail',
        inMenu: false,
        requiredPermissions: ['EDIT_ANY_TRIP', 'SEE_ANY_TRIP'],
    },
    USERLIST: {
        path: '/auth/users',
        service: ServiceName.AUTH,
        name: 'users',
        inMenu: true,
        requiredPermissions: ['SEE_MANAGED_USER', 'SEE_ANY_USER'],
    },
    USERDETAIL: {
        path: '/auth/users/:id',
        service: ServiceName.AUTH,
        name: 'userdetail',
        inMenu: false,
        requiredPermissions: ['SEE_MANAGED_USER', 'SEE_ANY_USER'],
    },
    TELEMETRYLIST: {
        path: '/telemetry/units',
        service: ServiceName.TELEMETRY,
        name: 'unit_list',
        inMenu: true,
        requiredPermissions: ['SEE_MANAGED_TELEMETRY_UNIT', 'SEE_ANY_TELEMETRY_UNIT'],
    },
    TELEMETRYDETAIL: {
        path: '/telemetry/units/:id',
        service: ServiceName.TELEMETRY,
        name: 'telemetrydetail',
        inMenu: false,
        requiredPermissions: ['SEE_MANAGED_TELEMETRY_UNIT', 'SEE_ANY_TELEMETRY_UNIT'],
    },
    LOGBOOK_ANALYSIS: {
        path: '/analysis/logbook/:id',
        name: 'logbook analysis',
        inMenu: false,
        requiredPermissions: ['SEE_ANY_TRIP', 'EDIT_ANY_TRIP'],
    },
};
export default features;
