import { intervalToDuration } from 'date-fns';
import { BASE_URL, pagedRequest, request } from './base';
import { buildQuery } from './query';
const TRIP_URL = `https://trip.${BASE_URL}`;
export function mapTripFromDTO(dto) {
    const dur = intervalToDuration({ start: new Date(dto.startTime), end: new Date(dto.endTime) });
    return Object.assign(Object.assign({}, dto), { startTime: new Date(dto.startTime), endTime: new Date(dto.endTime), creationTime: new Date(dto.creationTime), editableUntil: new Date(dto.editableUntil), durationString: `${dur.hours ? dur.hours : ''}${dur.hours ? 'h ' : ''}${dur.minutes ? dur.minutes : ''}${dur.minutes ? 'm ' : ''}${dur.seconds ? dur.seconds : ''}${dur.seconds ? 's' : ''}`.trim() });
}
export function getTrip(id) {
    return request(`${TRIP_URL}/trip/${id}`).then((res) => (res.payload ? mapTripFromDTO(res.payload) : null));
}
export function getTrips(page, query) {
    return pagedRequest(`${TRIP_URL}/trip${query ? '?query=' + buildQuery(query) : ''}`, {
        page: page !== null && page !== void 0 ? page : undefined,
    }).then((res) => {
        var _a;
        if (!res) {
            return { page: { page: 0, totalItems: 0, totalPages: 0 }, payload: null };
        }
        return {
            page: res.page,
            payload: (_a = res.payload) === null || _a === void 0 ? void 0 : _a.map(mapTripFromDTO),
        };
    });
}
export function getAllTrips(query) {
    const q = typeof query === 'object' ? buildQuery(query) : query;
    return request(`${TRIP_URL}/trip${query ? '?query=' + q : ''}`, {
        headers: {
            range: 'items= 0-1000',
        },
    }).then((res) => {
        var _a;
        if (!res) {
            return null;
        }
        return (_a = res.payload) === null || _a === void 0 ? void 0 : _a.map(mapTripFromDTO);
    });
}
export function patchTrip(trip) {
    return request(`${TRIP_URL}/trip/${trip.id}`, {
        method: 'PATCH',
        body: JSON.stringify(trip),
    }).then((res) => (res.payload ? mapTripFromDTO(res.payload) : null));
}
export function postTrip(trip) {
    return request(`${TRIP_URL}/trip`, {
        method: 'POST',
        body: JSON.stringify(trip),
    }).then((res) => (res.payload ? mapTripFromDTO(res.payload) : null));
}
export function processTrip(id) {
    return request(`${TRIP_URL}/trip/process/${id}`, { method: 'PATCH' }).then((res) => res.payload ? mapTripFromDTO(res.payload) : null);
}
export function alignTripsAfter(id) {
    return request(`${TRIP_URL}/trip/${id}/align?alignAddress=true`, { method: 'PUT' });
}
function mapMileageAdjustmentFromDTO(dto) {
    return Object.assign(Object.assign({}, dto), { creation: new Date(dto.creation) });
}
export function getMileageAdjustmentsForVehicle(vehicleId) {
    return request(`${TRIP_URL}/mileageAdjustment/${vehicleId}`).then((res) => res.payload ? res.payload.map(mapMileageAdjustmentFromDTO) : null);
}
