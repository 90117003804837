import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer, { routeConfig } from './rootReducer';
const middleware = getDefaultMiddleware({
    serializableCheck: false,
});
middleware.push(routeConfig.middleware);
const store = configureStore({
    reducer: rootReducer,
    middleware,
    enhancers: [routeConfig.enhancer],
});
store.dispatch(store.getState().location);
export default store;
// setup hot reload
if (process.env.NODE_ENV != 'production') {
    window.store = store;
}
if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default;
        store.replaceReducer(newRootReducer);
    });
}
