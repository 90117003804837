import { de, enUS as en } from 'date-fns/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import ICU from 'i18next-icu';
import localeDE from 'i18next-icu/locale-data/de';
import localeEN from 'i18next-icu/locale-data/en';
import { initReactI18next } from 'react-i18next';
i18n.use(Backend)
    .use(new ICU({ localeData: [localeDE, localeEN] }))
    .use(LanguageDetector)
    .use(initReactI18next) // bind react-i18next to the instance
    .init({
    fallbackLng: 'en',
    keySeparator: false,
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    react: {
        useSuspense: false,
    },
});
// add locale to window (for formatters)
i18n.on('initialized', function () {
    window.__localeID__ = i18n.language;
    dateFnsLocale = i18n.language === 'de' ? de : en;
});
i18n.on('languageChanged', function () {
    window.__localeID__ = i18n.language;
    dateFnsLocale = i18n.language === 'de' ? de : en;
});
export let dateFnsLocale = en;
export default i18n;
