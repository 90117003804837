import { createSlice } from '@reduxjs/toolkit';
import { loadMileageAdjustmentsForVehicle } from './trip.actions';
import { getInitialPageState, getInitialState, paginationReducer, selectPageReducer, } from '../../pagination';
const initialState = Object.assign(Object.assign({}, getInitialState()), { byOwner: {}, filter: { driverUserId: '', id: '', visitedCompany: '', visitedContact: '', purpose: '', detourReason: '' }, mileageAdjustments: {} });
export default createSlice({
    name: 'trip',
    initialState,
    reducers: {
        getListSuccess(state, action) {
            paginationReducer(state, action);
            updateOwnerList(state, action.payload.list);
        },
        searchSuccess(state, action) {
            paginationReducer(state, action);
            updateOwnerList(state, action.payload.list);
        },
        searchFailure(state, action) {
            state.byQuery[action.payload.queryString] = getInitialPageState(action.payload.query);
        },
        pageSelect(state, action) {
            selectPageReducer(state, action);
            state.filter = action.payload.query;
        },
        loadTripSuccess(state, action) {
            state.items[action.payload.id] = action.payload;
        },
        loadTripsSuccess(state, action) {
            action.payload.map((t) => (state.items[t.id] = t));
        },
    },
    extraReducers: {
        [loadMileageAdjustmentsForVehicle.fulfilled.toString()]: (state, action) => {
            var _a;
            state.mileageAdjustments[action.payload.vehicleId] = (_a = action.payload.adjustments) !== null && _a !== void 0 ? _a : [];
        },
    },
});
function updateOwnerList(state, list) {
    list.forEach((t) => {
        var _a;
        if (!t.driverUserId) {
            return;
        }
        state.byOwner[t.driverUserId] = [...((_a = state.byOwner[t.driverUserId]) !== null && _a !== void 0 ? _a : []), t.id].filter((v, i, a) => a.indexOf(v) === i);
    });
}
