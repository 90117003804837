import { createSlice } from '@reduxjs/toolkit';
const loadingSlice = createSlice({
    name: 'loading',
    initialState: 0,
    reducers: {
        incLoading(state) {
            return state + 1;
        },
        decLoading(state) {
            return Math.max(state - 1, 0);
        },
    },
});
export default loadingSlice;
