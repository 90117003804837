export const BASE_URL = {
    dev: 'carsync-devel.de',
    stage: 'carsync-staging.de',
    prod: 'carsync.de',
}[CS_ENV];
export const PAGE_SIZE = 50;
let _token = null;
export const setToken = (token) => {
    _token = token;
};
export const getToken = () => _token;
const defaultHeaders = {
    'Content-Type': 'application/json',
};
export function request(url, customConfig = {}) {
    const config = Object.assign(Object.assign({ method: customConfig.method || 'GET', redirect: 'follow', referrerPolicy: 'no-referrer' }, customConfig), { headers: Object.assign(Object.assign(Object.assign({}, defaultHeaders), customConfig.headers), { Authorization: _token ? `Bearer ${_token}` : '' }) });
    return fetch(url, config).then(async (res) => {
        const contentRange = res.headers.get('content-range');
        const contentType = res.headers.get('content-type');
        const shouldContainPayload = contentType && contentType.indexOf('application/json') != -1;
        const payload = shouldContainPayload ? (await res.json()) : null;
        if (res.status < 200 || res.status > 299) {
            return Promise.reject(payload);
        }
        return {
            page: getPageInfo(contentRange),
            payload,
        };
    });
}
export function pagedRequest(url, customConfig = {}) {
    const config = Object.assign(Object.assign({}, customConfig), { headers: Object.assign(Object.assign({}, customConfig.headers), { range: customConfig.page != null
                ? `items=${customConfig.page * PAGE_SIZE}-${(customConfig.page + 1) * PAGE_SIZE - 1}`
                : '' }) });
    return request(url, config);
}
function getPageInfo(contentRange) {
    const match = (contentRange !== null && contentRange !== void 0 ? contentRange : '').match(/items (\d+)-(\d+)\/(\d+|\*)/);
    return {
        page: match ? Math.floor(parseInt(match[1]) / PAGE_SIZE) : 0,
        totalItems: match && match[3] != '*' ? parseInt(match[3]) : -1,
        totalPages: match && match[3] != '*' ? Math.ceil(parseInt(match[3]) / PAGE_SIZE) : -1,
    };
}
