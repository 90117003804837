import { loadVehicle, queryVehicles } from '@app/state/services/vehicle/vehicle.actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildQuery } from '../../../libcarsync/query';
import * as tripApi from '../../../libcarsync/trip';
import store from '../../index';
import { loadUser, queryUsers } from '../auth/auth.actions';
import loadingSlice from '../loading';
import tripSlice from './trip.slice';
export const getList = createAsyncThunk('trip/list', (page, thunkApi) => {
    tripApi
        .getTrips(page, {})
        .then((res) => {
        if (!res.payload) {
            return;
        }
        thunkApi.dispatch(tripSlice.actions.getListSuccess({
            query: {},
            list: res.payload,
            page: res.page.page,
            totalItems: res.page.totalItems,
            totalPages: res.page.totalPages,
        }));
        const ownerIds = res.payload.map((i) => i.driverUserId).filter((x) => x != null);
        if (ownerIds.length) {
            thunkApi.dispatch(queryUsers({ page: 0, query: { id: ownerIds } }));
        }
    })
        .catch(console.error);
});
export const queryTrips = createAsyncThunk('trip/list/query', (arg, thunkApi) => {
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    const queryString = buildQuery(arg.query);
    thunkApi.dispatch(tripSlice.actions.pageSelect(arg));
    return tripApi
        .getTrips(arg.page, arg.query)
        .then((res) => {
        thunkApi.dispatch(loadingSlice.actions.decLoading());
        if (!res.payload) {
            return [];
        }
        thunkApi.dispatch(tripSlice.actions.searchSuccess({
            query: arg.query,
            list: res.payload,
            page: res.page.page,
            totalItems: res.page.totalItems,
            totalPages: res.page.totalPages,
        }));
        const ownerIds = res.payload
            .map((i) => i.driverUserId)
            .filter((x) => x != null && thunkApi.getState().auth.items[x] === undefined)
            .filter((x, i, a) => a.indexOf(x) == i);
        const vehicleIds = res.payload
            .map((i) => i.vehicleId)
            .filter((x) => x != null && thunkApi.getState().vehicle.items[x] === undefined)
            .filter((x, i, a) => a.indexOf(x) == i);
        if (ownerIds.length) {
            thunkApi.dispatch(queryUsers({ page: 0, query: { id: ownerIds } }));
        }
        if (vehicleIds.length) {
            thunkApi.dispatch(queryVehicles({ page: 0, query: { id: vehicleIds } }));
        }
        return res.payload;
    })
        .catch((err) => {
        thunkApi.dispatch(tripSlice.actions.searchFailure({ query: arg.query, queryString }));
        thunkApi.dispatch(loadingSlice.actions.decLoading());
        console.error(err);
        return [];
    });
});
export const loadAllTrips = createAsyncThunk('trip/loadAll', (arg, thunkApi) => {
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    return tripApi
        .getAllTrips(arg)
        .then((res) => {
        thunkApi.dispatch(loadingSlice.actions.decLoading());
        if (!res) {
            return [];
        }
        thunkApi.dispatch(tripSlice.actions.loadTripsSuccess(res));
        const ownerIds = res
            .map((i) => i.driverUserId)
            .filter((x) => x != null && thunkApi.getState().auth.items[x] === undefined)
            .filter((x, i, a) => a.indexOf(x) == i);
        const vehicleIds = res
            .map((i) => i.vehicleId)
            .filter((x) => x != null && thunkApi.getState().vehicle.items[x] === undefined)
            .filter((x, i, a) => a.indexOf(x) == i);
        if (ownerIds.length) {
            thunkApi.dispatch(queryUsers({ page: 0, query: { id: ownerIds } }));
        }
        if (vehicleIds.length) {
            thunkApi.dispatch(queryVehicles({ page: 0, query: { id: vehicleIds } }));
        }
        return res;
    })
        .catch((err) => {
        thunkApi.dispatch(loadingSlice.actions.decLoading());
        console.error(err);
        return [];
    });
});
export const loadTrip = createAsyncThunk('trip/load', (arg, thunkApi) => {
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    tripApi
        .getTrip(arg.id)
        .then((res) => {
        if (res) {
            if (arg.loadEmbeds && res.driverUserId && thunkApi.getState().auth.items[res.driverUserId] == null) {
                thunkApi.dispatch(loadUser(res.driverUserId));
            }
            if (arg.loadEmbeds && res.vehicleId && thunkApi.getState().vehicle.items[res.vehicleId] == null) {
                thunkApi.dispatch(loadVehicle(res.vehicleId));
            }
            if (arg.loadEmbeds &&
                res.previousTripId &&
                thunkApi.getState().trip.items[res.previousTripId] == null) {
                thunkApi.dispatch(loadTrip({ id: res.previousTripId, loadEmbeds: false }));
            }
            thunkApi.dispatch(tripSlice.actions.loadTripSuccess(res));
        }
    })
        .finally(() => thunkApi.dispatch(loadingSlice.actions.decLoading()));
});
export const updateTrip = (trip) => (dispatch) => {
    return tripApi.patchTrip(trip).then((res) => {
        if (res) {
            dispatch(tripSlice.actions.loadTripSuccess(res));
        }
    });
};
export const createTrip = createAsyncThunk('trip/create', (trip, thunkApi) => {
    return tripApi.postTrip(trip).then((res) => {
        if (res) {
            thunkApi.dispatch(tripSlice.actions.loadTripSuccess(res));
        }
        return res;
    });
});
export function triggerTripProcessing(id) {
    store.dispatch(loadingSlice.actions.incLoading());
    return tripApi
        .processTrip(id)
        .then((t) => {
        if (t) {
            store.dispatch(tripSlice.actions.loadTripSuccess(t));
        }
        store.dispatch(loadingSlice.actions.decLoading());
        return t;
    })
        .catch((e) => {
        store.dispatch(loadingSlice.actions.decLoading());
        throw e;
    });
}
export const triggerTripAlignment = (id) => {
    return tripApi.alignTripsAfter(id);
};
export const loadMileageAdjustmentsForVehicle = createAsyncThunk('mileageAdjust/byVehicle', async (vehicleId) => {
    try {
        const adjustments = await tripApi.getMileageAdjustmentsForVehicle(vehicleId);
        return { vehicleId, adjustments };
    }
    catch (e) {
        console.error(e);
        return { vehicleId, adjustments: [] };
    }
});
