import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
const viewmodelSlice = createSlice({
    name: 'viewModels',
    initialState: {},
    reducers: {
        updateModel: (state, action) => {
            const oldState = state[action.payload.id];
            state[action.payload.id] = Object.assign(Object.assign({}, oldState), action.payload.values);
        },
    },
});
export const updateModel = createAsyncThunk('viewmodel/update', (update, thunkApi) => {
    if (typeof update.values == 'function') {
        const oldState = thunkApi.getState().viewmodels[update.id];
        const newState = produce(oldState !== null && oldState !== void 0 ? oldState : {}, update.values);
        thunkApi.dispatch(viewmodelSlice.actions.updateModel({ id: update.id, values: newState }));
    }
    thunkApi.dispatch(viewmodelSlice.actions.updateModel(update));
});
export default viewmodelSlice.reducer;
