import '@app/scss/main.global.scss';
import React from 'react';
import reactDom from 'react-dom';
import Modal from 'react-modal';
import store from './state/index';
import './views/i18n';
import i18n from './views/i18n';
// import './vanillaState';
if ('serviceWorker' in navigator) {
    console.debug('[sw] supported');
    // navigator.serviceWorker.register('/sw.js');
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
}
Modal.setAppElement('#mount');
const mount = document.getElementById('mount');
const render = async () => {
    const App = (await import('@app/views/App')).default;
    reactDom.render(React.createElement(App, { store: store }), mount);
};
// wait for i18n to be available and then render
i18n.loadNamespaces(['translation', 'trip']);
i18n.on('initialized', render);
if (process.env.NODE_ENV !== 'production') {
    import('./dev');
    if (module.hot) {
        module.hot.accept('@app/views/App', render);
    }
}
