import { createSlice } from '@reduxjs/toolkit';
import { getInitialState, paginationReducer, selectPageReducer, getInitialPageState, } from '../../pagination';
const initialState = Object.assign(Object.assign({}, getInitialState()), { byOwner: {}, filter: { ownerId: '', id: '' } });
export default createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        getListSuccess(state, action) {
            paginationReducer(state, action);
            updateOwnerList(state, action.payload.list);
        },
        searchSuccess(state, action) {
            paginationReducer(state, action);
            updateOwnerList(state, action.payload.list);
        },
        searchFailure(state, action) {
            state.byQuery[action.payload.queryString] = getInitialPageState(action.payload.query);
        },
        pageSelect(state, action) {
            selectPageReducer(state, action);
            state.filter = action.payload.query;
        },
        loadVehicleArraySuccess(state, action) {
            action.payload.map((v) => (state.items[v.id] = v));
            updateOwnerList(state, action.payload);
        },
        loadVehicleSuccess(state, action) {
            state.items[action.payload.id] = action.payload;
        },
    },
});
function updateOwnerList(state, list) {
    list.forEach((v) => {
        var _a;
        if (!v.ownerId) {
            return;
        }
        state.byOwner[v.ownerId] = [...((_a = state.byOwner[v.ownerId]) !== null && _a !== void 0 ? _a : []), v.id].filter((v, i, a) => a.indexOf(v) === i);
    });
}
