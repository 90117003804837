export const ORDERBY = Symbol.for('orderby key');
/**
 * takes a Query object and builds it into an acceptable format for the backend
 * the language itself is sql-ish with odd requirements.
 *
 */
export function buildQuery(params) {
    return Object.entries(params)
        .filter((e) => (typeof e[1] !== 'string' || e[1] != '') && e[1] != null)
        .map(([key, val]) => {
        return [key.replace('_', '.'), val];
    })
        .reduce((acc, val) => {
        const queryPart = Array.isArray(val[1]) ? serializeArray(val[0], val[1]) : serializeValue(val[0], val[1]);
        return [...acc, queryPart];
    }, [])
        .filter((e) => e != '')
        .join(' AND ')
        .concat(params[ORDERBY] ? ` ORDER BY ${params[ORDERBY]}` : '');
}
function serializeArray(key, vals) {
    return `${key} IN (${vals.map((i) => JSON.stringify(i)).join(', ')})`;
}
function serializeValue(key, val) {
    if (typeof val === 'string' && (val.startsWith('%') || val.endsWith('%'))) {
        return `${key} LIKE ${JSON.stringify(val)}`;
    }
    else {
        return `${key} = ${JSON.stringify(val)}`;
    }
}
const operatorMap = {
    eq: '=',
    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',
    like: 'LIKE',
};
export class QueryBuilder {
    constructor() {
        this.conditions = [];
        this.order = '';
    }
    where(key, value, type = 'eq') {
        this.conditions.push({ key, value, type });
        return this;
    }
    and(key, value, type = 'eq') {
        this.conditions.push({ key, value, type, concat: 'AND' });
        return this;
    }
    or(key, value, type = 'eq') {
        this.conditions.push({ key, value, type, concat: 'OR' });
        return this;
    }
    orderBy(key, sort) {
        this.order = ` ORDER BY ${key} ${sort.toUpperCase()}`;
        return this;
    }
    build() {
        return encodeURIComponent(this.conditions
            .map((curr) => {
            if (Array.isArray(curr.value)) {
                return `${curr.concat ? ` ${curr.concat} ` : ''}${curr.key} IN (${curr.value.map((i) => JSON.stringify(i)).join(', ')})`;
            }
            return `${curr.concat ? ` ${curr.concat} ` : ''}${curr.key} ${operatorMap[curr.type]} ${JSON.stringify(curr.value)}`;
        }, '')
            .join('')
            .concat(this.order));
    }
}
