var _a, _b, _c, _d, _e;
import { createSlice } from '@reduxjs/toolkit';
import { getToken, setToken } from '../../../libcarsync/base';
import * as authActions from './auth.actions';
import { getInitialState, paginationReducer, selectPageReducer, } from '../../pagination';
const existingState = loadExistingAuth();
const initialState = Object.assign({ email: (_a = existingState === null || existingState === void 0 ? void 0 : existingState.email) !== null && _a !== void 0 ? _a : '', userId: (_b = existingState === null || existingState === void 0 ? void 0 : existingState.userId) !== null && _b !== void 0 ? _b : null, token: (_c = existingState === null || existingState === void 0 ? void 0 : existingState.token) !== null && _c !== void 0 ? _c : null, isLoggedIn: (_d = existingState === null || existingState === void 0 ? void 0 : existingState.isLoggedIn) !== null && _d !== void 0 ? _d : null, permissions: (_e = existingState === null || existingState === void 0 ? void 0 : existingState.permissions) !== null && _e !== void 0 ? _e : [], roles: {}, filter: {
        firstName: '',
        lastName: '',
        email: '',
        accountType: '',
        role_name: '',
        mobilePhoneNumber: '',
    } }, getInitialState());
export default createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            var _a, _b;
            state.email = action.payload.email;
            state.userId = action.payload.id;
            state.token = (_a = getToken()) !== null && _a !== void 0 ? _a : '';
            state.isLoggedIn = true;
            state.permissions = (_b = action.payload.permissions) !== null && _b !== void 0 ? _b : [];
            sessionStorage.setItem('state:auth', JSON.stringify({
                email: state.email,
                userId: state.userId,
                token: state.token,
                isLoggedIn: state.isLoggedIn,
                permissions: state.permissions,
            }));
        },
        loginFailure: (state) => {
            state.isLoggedIn = false;
            state.permissions = [];
            state.items = {};
        },
        logoutSuccess: (state) => {
            state.isLoggedIn = false;
            state.userId = null;
            state.permissions = [];
            state.items = {};
            state.token = undefined;
        },
        sessionSuccess: (state, action) => {
            state.permissions = action.payload;
        },
        sessionFailure: (state) => {
            state.permissions = [];
        },
        pageSelect(state, action) {
            selectPageReducer(state, action);
            state.filter = action.payload.query;
        },
        loadUsersSuccess: paginationReducer,
        loadUsersFailure: paginationReducer,
        loadUserSuccess(state, action) {
            state.items[action.payload.id] = action.payload;
        },
        loadUserArraySuccess(state, action) {
            action.payload.map((u) => (state.items[u.id] = u));
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authActions.loadRoles.fulfilled.toString(), (state, action) => {
            state.roles = action.payload.reduce((acc, curr) => (Object.assign(Object.assign({}, acc), { [curr.id]: curr.name })), {});
        });
    },
});
function loadExistingAuth() {
    const savedAuth = window.sessionStorage.getItem('state:auth');
    if (savedAuth) {
        const auth = JSON.parse(savedAuth);
        const tokenPayload = auth.token.split(' ')[1];
        const expiration = JSON.parse(atob(tokenPayload.split('.')[1])).exp;
        const isExpired = expiration < Date.now() / 1000;
        if (isExpired) {
            window.sessionStorage.removeItem('state:auth');
            return null;
        }
        else {
            setToken(auth.token);
            return auth;
        }
    }
}
