import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
let id = 0;
export const createTimedNotification = createAsyncThunk('notification/timed', (arg, thunkApi) => {
    const notification = Object.assign(Object.assign({}, arg), { id: (++id).toString(), created: new Date() });
    thunkApi.dispatch(notificationSlice.actions.addNotification(notification));
    if (arg.timeout) {
        setTimeout(() => thunkApi.dispatch(notificationSlice.actions.removeNotification(notification.id)), arg.timeout);
    }
    return notification.id;
});
const notificationSlice = createSlice({
    initialState: {},
    name: 'notification',
    reducers: {
        addNotification: (state, action) => {
            state[action.payload.id] = action.payload;
        },
        removeNotification: (state, action) => {
            delete state[action.payload];
        },
    },
});
export default notificationSlice;
