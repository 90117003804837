import { createSlice } from '@reduxjs/toolkit';
import { getInitialPageState, getInitialState, paginationReducer, selectPageReducer, } from '../../pagination';
const initialState = Object.assign(Object.assign({}, getInitialState()), { byOwner: {}, filter: { ownerId: '', id: '', imei: '', hardwareId: '' } });
export default createSlice({
    name: 'telemetry',
    initialState,
    reducers: {
        getListSuccess(state, action) {
            paginationReducer(state, action);
            updateOwnerList(state, action.payload.list);
        },
        searchSuccess(state, action) {
            paginationReducer(state, action);
            updateOwnerList(state, action.payload.list);
        },
        searchFailure(state, action) {
            state.byQuery[action.payload.queryString] = getInitialPageState(action.payload.query);
        },
        pageSelect(state, action) {
            selectPageReducer(state, action);
            state.filter = action.payload.query;
        },
        loadTelemetryUnitSuccess(state, action) {
            state.items[action.payload.id] = action.payload;
        },
    },
});
function updateOwnerList(state, list) {
    list.forEach((tu) => {
        var _a;
        if (!tu.ownerId) {
            return;
        }
        state.byOwner[tu.ownerId] = [...((_a = state.byOwner[tu.ownerId]) !== null && _a !== void 0 ? _a : []), tu.id].filter((v, i, a) => a.indexOf(v) === i);
    });
}
