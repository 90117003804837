import { combineReducers } from '@reduxjs/toolkit';
import * as qs from 'querystring';
import { connectRoutes } from 'redux-first-router';
import features from '../features';
import authSlice from './services/auth/auth.slice';
import loadingSlice from './services/loading';
import notificationSlice from './services/notificationSlice';
import telemetrySlice from './services/telemetry/telemetry.slice';
import tripSlice from './services/trip/trip.slice';
import vehicleSlice from './services/vehicle/vehicle.slice';
import viewmodelReducer from './viewmodelReducer';
const routesMap = Object.entries(features).reduce((acc, [key, val]) => {
    return Object.assign(Object.assign({}, acc), { [key]: val });
}, {});
export const routeConfig = connectRoutes(routesMap, { scrollTop: true, querySerializer: qs, initialDispatch: false });
const rootReducer = combineReducers({
    auth: authSlice.reducer,
    telemetry: telemetrySlice.reducer,
    vehicle: vehicleSlice.reducer,
    trip: tripSlice.reducer,
    location: routeConfig.reducer,
    loading: loadingSlice.reducer,
    viewmodels: viewmodelReducer,
    notifications: notificationSlice.reducer,
});
export default rootReducer;
