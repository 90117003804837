import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildQuery } from '../../../libcarsync/query';
import * as vehicleApi from '../../../libcarsync/vehicle';
import { loadUser, queryUsers } from '../auth/auth.actions';
import loadingSlice from '../loading';
import vehicleSlice from './vehicle.slice';
export const getList = (page) => (dispatch, getState) => {
    vehicleApi
        .getVehicles(page, {})
        .then((res) => {
        if (!res.payload) {
            return;
        }
        dispatch(vehicleSlice.actions.getListSuccess({
            query: {},
            list: res.payload,
            page: res.page.page,
            totalItems: res.page.totalItems,
            totalPages: res.page.totalPages,
        }));
        const ownerIds = res.payload.map((i) => i.ownerId).filter((x) => x != null && !getState().auth.items[x]);
        if (ownerIds.length) {
            dispatch(queryUsers({ page: 0, query: { id: ownerIds } }));
        }
    })
        .catch(console.error);
};
export const queryVehicles = createAsyncThunk('vehicle/vehicle/query', (arg, thunkApi) => {
    var _a;
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    const queryString = buildQuery(arg.query);
    vehicleApi
        .getVehicles((_a = arg.page) !== null && _a !== void 0 ? _a : 0, arg.query)
        .then((res) => {
        thunkApi.dispatch(loadingSlice.actions.decLoading());
        if (!res.payload) {
            return;
        }
        thunkApi.dispatch(vehicleSlice.actions.searchSuccess({
            query: arg.query,
            list: res.payload,
            page: res.page.page,
            totalItems: res.page.totalItems,
            totalPages: res.page.totalPages,
        }));
        const ownerIds = res.payload
            .map((i) => i.ownerId)
            .filter((x) => x != null && !thunkApi.getState().auth.items[x]);
        if (ownerIds.length) {
            thunkApi.dispatch(queryUsers({ page: 0, query: { id: ownerIds } }));
        }
    })
        .catch((err) => {
        thunkApi.dispatch(vehicleSlice.actions.searchFailure({ query: arg.query, queryString }));
        thunkApi.dispatch(loadingSlice.actions.decLoading());
        console.error(err);
    });
});
export const loadVehicle = createAsyncThunk('vehicle/load', (id, thunkApi) => {
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    return vehicleApi
        .getVehicle(id)
        .then((res) => {
        if (res) {
            thunkApi.dispatch(vehicleSlice.actions.loadVehicleSuccess(res));
            if (res.ownerId && !thunkApi.getState().auth.items[res.ownerId]) {
                thunkApi.dispatch(loadUser(res.ownerId));
            }
        }
        return res;
    })
        .finally(() => {
        thunkApi.dispatch(loadingSlice.actions.decLoading());
    });
});
export const findVehicles = createAsyncThunk('vehicle/find', (query, thunkApi) => {
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    return vehicleApi
        .getAllVehicles(query)
        .then((res) => {
        if (res && res.payload) {
            thunkApi.dispatch(vehicleSlice.actions.loadVehicleArraySuccess(res.payload));
        }
        return (res === null || res === void 0 ? void 0 : res.payload) ? res === null || res === void 0 ? void 0 : res.payload : [];
    })
        .catch((err) => {
        console.log(err);
        return [];
    })
        .finally(() => {
        thunkApi.dispatch(loadingSlice.actions.decLoading());
    });
});
