import i18n from '@app/views/i18n';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { redirect } from 'redux-first-router';
import * as authApi from '../../../libcarsync/auth';
import loadingSlice from '../loading';
import { createTimedNotification } from '../notificationSlice';
import authSlice from './auth.slice';
export const login = createAsyncThunk('auth/login', (arg, thunkApi) => {
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    authApi
        .login(arg.username, arg.password)
        .then(async (res) => {
        if (!res) {
            throw new Error('auth failed unexpedctedly');
        }
        thunkApi.dispatch(loadRoles());
        thunkApi.dispatch(loadUser(res.id));
        const perms = await authApi.getSession();
        thunkApi.dispatch(authSlice.actions.loginSuccess({ email: arg.username, permissions: perms, id: res.id }));
        thunkApi.dispatch(redirect({
            type: thunkApi.getState().location.type,
            payload: thunkApi.getState().location.payload,
        }));
        thunkApi.dispatch(loadingSlice.actions.decLoading());
    })
        .catch(() => {
        thunkApi.dispatch(loadingSlice.actions.decLoading());
        thunkApi.dispatch(authSlice.actions.loginFailure());
    });
});
export const removeUser = createAsyncThunk('auth/user/remove', (userId) => {
    authApi.remove(userId).catch(console.error);
});
export const resendUserEmail = createAsyncThunk('auth/user/confirmEmail', (userId, thunkApi) => {
    authApi
        .resendEmailConfirmation(userId)
        .then(() => {
        thunkApi.dispatch(createTimedNotification({
            kind: 'success',
            title: '',
            subtitle: i18n.t('action_confirmed'),
            timeout: 4000,
        }));
    })
        .catch(console.error);
});
export const resetUserPassword = createAsyncThunk('auth/user/resetPassword', (userEmail, thunkApi) => {
    authApi
        .passwordReset(userEmail)
        .then(() => {
        thunkApi.dispatch(createTimedNotification({
            kind: 'success',
            title: '',
            subtitle: i18n.t('action_confirmed'),
            timeout: 4000,
        }));
    })
        .catch(console.error);
});
export const logout = createAsyncThunk('auth/logout', (arg, thunkApi) => {
    authApi.logout();
    window.sessionStorage.removeItem('state:auth');
    thunkApi.dispatch(authSlice.actions.logoutSuccess());
});
export const loadPermissions = createAsyncThunk('auth/session', (arg, thunkApi) => {
    authApi
        .getSession()
        .then((res) => thunkApi.dispatch(authSlice.actions.sessionSuccess(res)))
        .catch(() => thunkApi.dispatch(authSlice.actions.sessionFailure()));
});
export const loadRoles = createAsyncThunk('auth/roles/load', async () => {
    return authApi.getRoles();
});
export const loadUser = createAsyncThunk('auth/user/load', (id, thunkApi) => {
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    return authApi
        .getUser(id)
        .then((res) => {
        if (res) {
            thunkApi.dispatch(authSlice.actions.loadUserSuccess(res));
        }
        return res;
    })
        .finally(() => thunkApi.dispatch(loadingSlice.actions.decLoading()));
});
export const findUsers = createAsyncThunk('auth/user/load', (query, thunkApi) => {
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    return authApi
        .getAllUsers(query)
        .then((res) => {
        if (res && res.payload) {
            thunkApi.dispatch(authSlice.actions.loadUserArraySuccess(res.payload));
        }
        return (res === null || res === void 0 ? void 0 : res.payload) ? res === null || res === void 0 ? void 0 : res.payload : [];
    })
        .catch((err) => {
        console.log(err);
        return [];
    })
        .finally(() => {
        thunkApi.dispatch(loadingSlice.actions.decLoading());
    });
});
export const updateUserRole = createAsyncThunk('auth/roles/updateUserRole', (arg, thunkApi) => {
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    authApi
        .assignRole(arg.user, arg.roleId)
        .then((res) => {
        if (!res.payload) {
            return;
        }
        thunkApi.dispatch(createTimedNotification({
            kind: 'success',
            title: '',
            subtitle: i18n.t('action_confirmed'),
            timeout: 4000,
        }));
    })
        .catch((error) => {
        console.error(error);
    })
        .finally(() => thunkApi.dispatch(loadingSlice.actions.decLoading()));
});
export const updateUser = createAsyncThunk('auth/user/update', (user, thunkApi) => {
    return authApi
        .patchUser(user)
        .then((res) => {
        if (!res.payload) {
            return;
        }
        thunkApi.dispatch(createTimedNotification({
            kind: 'success',
            title: '',
            subtitle: i18n.t('action_confirmed'),
            timeout: 4000,
        }));
        return res.payload;
    })
        .catch((error) => {
        console.error(error);
    });
});
export const queryUsers = createAsyncThunk('auth/user/query', async (arg, thunkApi) => {
    var _a;
    thunkApi.dispatch(loadingSlice.actions.incLoading());
    authApi
        .getUsers((_a = arg.page) !== null && _a !== void 0 ? _a : 0, arg.query)
        .then((res) => {
        if (!res.payload) {
            thunkApi.dispatch(loadingSlice.actions.decLoading());
            return;
        }
        thunkApi.dispatch(authSlice.actions.loadUsersSuccess({
            query: arg.query,
            list: res.payload,
            page: res.page.page,
            totalItems: res.page.totalItems,
            totalPages: res.page.totalPages,
        }));
        thunkApi.dispatch(loadingSlice.actions.decLoading());
    })
        .catch((e) => {
        var _a;
        console.error(e);
        thunkApi.dispatch(loadingSlice.actions.decLoading());
        thunkApi.dispatch(authSlice.actions.loadUsersFailure({
            query: arg.query,
            page: (_a = arg.page) !== null && _a !== void 0 ? _a : 0,
            list: [],
            totalItems: 0,
            totalPages: 0,
        }));
    });
});
