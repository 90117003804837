import * as qs from 'querystring';
import { BASE_URL, pagedRequest, request, setToken } from './base';
import { buildQuery } from './query';
const AUTH_URL = `https://auth.${BASE_URL}`;
export function login(username, password) {
    return request(`${AUTH_URL}/login?tokenExpirationInterval=${60 * 60 * 12}`, {
        method: 'POST',
        body: JSON.stringify({ username, password }),
    }).then((res) => {
        setToken(res.payload.authorizationToken);
        return res.payload;
    });
}
export function resendEmailConfirmation(userId) {
    return request(`${AUTH_URL}/user/${userId}/resendConfirmationEmail`, {
        method: 'POST',
        body: JSON.stringify({}),
    });
}
export function remove(userId) {
    return request(`${AUTH_URL}/user/${userId}`, {
        method: 'DELETE',
    });
}
export function passwordReset(userEmail) {
    return request(`${AUTH_URL}/user/${encodeURIComponent(userEmail)}/passwordReset`, {
        method: 'POST',
        body: JSON.stringify({}),
    });
}
export function logout() {
    return request(`${AUTH_URL}/login`, { method: 'DELETE' });
}
export function getSession() {
    return request(`${AUTH_URL}/login/session`).then((res) => res.payload.sort());
}
export function getRoles() {
    return request(`${AUTH_URL}/role`, { method: 'GET' }).then((res) => res.payload.sort());
}
export function getRole(id) {
    return request(`${AUTH_URL}/role/${id}`, { method: 'GET' }).then((res) => res.payload);
}
export function assignRole(user, roleId) {
    return request(`${AUTH_URL}/user/${user.id}/role/${roleId}`, {
        method: 'PUT',
        body: JSON.stringify(user),
    });
}
export var AccountType;
(function (AccountType) {
    AccountType["CLASSIC"] = "CLASSIC";
    AccountType["GOOGLE"] = "GOOGLE";
    AccountType["APPLE"] = "APPLE";
    AccountType["LINKEDIN"] = "LINKEDIN";
    AccountType["ANONYMOUS"] = "ANONYMOUS";
})(AccountType || (AccountType = {}));
export function getUsers(page, query) {
    return pagedRequest(`${AUTH_URL}/user?${qs.encode({ query: buildQuery(query) })}`, { page });
}
export function getAllUsers(query) {
    const q = typeof query === 'object' ? buildQuery(query) : query;
    return request(`${AUTH_URL}/user${query ? '?query=' + q : ''}`);
}
export function getUser(id) {
    return request(`${AUTH_URL}/user/${id}`).then((res) => res.payload);
}
export function patchUser(user) {
    return request(`${AUTH_URL}/user/${user.id}`, {
        method: 'PATCH',
        body: JSON.stringify(user),
    });
}
export function getReadableName(user) {
    if (!user) {
        return '';
    }
    if (user.firstName || user.lastName) {
        return [user.salutation, user.firstName, user.lastName].filter(Boolean).join(' ');
    }
    else {
        return user.email;
    }
}
export function getFullUserIdentifiers(user) {
    return [user.salutation, user.firstName, user.lastName, '(' + user.email + ')'].filter(Boolean).join(' ');
}
